
































































































































































import Modal from '@/app/ui/components/Modal/index.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CloseLine from '@/app/ui/assets/close_line.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import { IOptions } from '@/app/infrastructures/misc/Constants/manualAdjustSaldo'
import { validationMixin } from 'vuelidate'
import { Validations } from 'vuelidate-property-decorators'
import {
  required,
  ValidationRule,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'
import controller from '@/app/ui/controllers/RewardAndPunishmentController'
import CalendarIcon from '@/app/ui/assets/ics_o_calendar.vue'
import { Utils } from '@/app/infrastructures/misc'
import Button from '@/app/ui/components/Button/index.vue'
import { CreateProgramRewardAndPunishmentRequest } from '@/data/payload/api/RewardAndPunishmentRequest'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { evaluationScheduleOptions } from '@/app/infrastructures/misc/Constants/rewardAndPunishment'

interface IForm {
  programName: string
  periodeStart: null | Date
  periodeEnd: null | Date
  evaluationSchedule: null | IOptions
}

interface IValidation {
  form: {
    programName: {
      required: () => ValidationRule
      minLength: ValidationRule
      maxLength: ValidationRule
    }
    periodeStart: {
      required: () => ValidationRule
      isAlreadyUsed: () => boolean
      isMoreThanToday: (value: Date | null) => boolean
    }
    periodeEnd: {
      required: () => ValidationRule
      isAlreadyUsed: () => boolean
      isMoreThanPeriodeStart: (value: Date | null) => boolean
    }
    evaluationSchedule: { required: () => ValidationRule }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    Modal,
    TextInput,
    DropdownSelect,
    DateTimePickerV2,
    CloseLine,
    CalendarIcon,
    Button,
    LoadingOverlay,
  },
})
export default class ModalCreateProgram extends Vue {
  @Prop({ type: Boolean }) public visible!: boolean
  @Prop({ type: Boolean }) public clearForm!: boolean

  controller = controller

  evaluationScheduleOptions = evaluationScheduleOptions

  form: IForm = {
    programName: '',
    periodeStart: null,
    periodeEnd: null,
    evaluationSchedule: null,
  }

  @Validations()
  validations(): IValidation {
    return {
      form: {
        programName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
        },
        periodeStart: {
          required,
          isAlreadyUsed: () => !this.isPeriodeAlreadyUsed,
          isMoreThanToday: this.isMoreThanToday,
        },
        periodeEnd: {
          required,
          isAlreadyUsed: () => !this.isPeriodeAlreadyUsed,
          isMoreThanPeriodeStart: this.isMoreThanPeriodeStart,
        },
        evaluationSchedule: { required },
      },
    }
  }

  get minDate(): Date {
    const date = new Date()
    date.setDate(date.getDate() + 1)

    return date
  }

  get periodeStartPlaceholder(): string {
    if (this.form.periodeStart) {
      return Utils.formatDateWithIDLocale(
        this.form.periodeStart.toISOString(),
        'DD/MM/YYYY'
      )
    }
    return 'DD/MM/YYYY'
  }

  get periodeEndPlaceholder(): string {
    if (this.form.periodeEnd) {
      return Utils.formatDateWithIDLocale(
        this.form.periodeEnd.toISOString(),
        'DD/MM/YYYY'
      )
    }
    return 'DD/MM/YYYY'
  }

  get programNameErrorMessage(): string {
    return this.$v.form.programName?.$dirty && this.$v.form.programName.$invalid
      ? !this.$v.form.programName?.required
        ? 'Nama program harus diisi'
        : 'Nama program harus 3 sampai 100 karakter'
      : ''
  }

  get periodeStartErrorMessage(): string {
    return !this.$v.form.periodeStart?.isAlreadyUsed
      ? 'Periode sudah dipakai, Cek lagi'
      : this.$v.form.periodeStart?.$dirty && !this.$v.form.periodeStart.required
      ? 'Periode mulai harus diisi'
      : ''
  }

  get periodeEndErrorMessage(): string {
    return !this.$v.form.periodeEnd?.isMoreThanPeriodeStart &&
      this.$v.form.periodeEnd?.$dirty
      ? 'Periode akhir lebih kecil, Cek lagi '
      : !this.$v.form.periodeEnd?.isAlreadyUsed
      ? 'Periode sudah dipakai, Cek lagi'
      : this.$v.form.periodeEnd.$dirty && !this.$v.form.periodeEnd.required
      ? 'Periode akhir harus diisi'
      : ''
  }

  get evaluationScheduleErrorMessage(): string {
    return this.$v.form.evaluationSchedule?.$dirty &&
      !this.$v.form.evaluationSchedule.required
      ? 'Jadwal evaluasi harus diisi'
      : ''
  }

  get isPeriodeAlreadyUsed(): boolean {
    return this.controller.isPeriodeAlreadyUsed
  }

  public onSubmit(): void {
    const payload = new CreateProgramRewardAndPunishmentRequest(
      this.form.programName,
      <Date>this.form.periodeStart,
      <Date>this.form.periodeEnd,
      <string>this.form.evaluationSchedule?.value
    )

    this.controller.createProgram(payload)
  }

  public closeModal(): void {
    this.resetForm()
    this.resetPeriodeError()
    this.$v.$reset()
    this.$emit('close')
  }

  private resetForm(): void {
    this.form = {
      evaluationSchedule: null,
      periodeEnd: null,
      periodeStart: null,
      programName: '',
    }
  }

  private isMoreThanPeriodeStart(value: Date | null): boolean {
    if (value && this.form.periodeStart) {
      return value.getTime() >= this.form.periodeStart.getTime()
    }

    return true
  }

  private isMoreThanToday(value: Date | null): boolean {
    if (value) {
      return (
        new Date(new Date().setHours(0, 0, 0, 0)).getTime() < value.getTime()
      )
    }

    return true
  }

  public resetPeriodeError(): void {
    this.controller.setIsPeriodeAlreadyUsed(false)
  }
}
