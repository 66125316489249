import { IOptions } from '@/data/infrastructures/misc/interfaces/pickupTimeManagement'

export enum periodeStatus {
  WAITING = 'Menunggu',
  ACTIVE = 'Aktif',
  EXPIRED = 'Kadaluarsa',
}

export enum evaluationStatus {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  QUARTER = 'quarterly',
}

export enum formattedEvaluationStatus {
  WEEKLY = 'Mingguan',
  MONTHLY = 'Bulanan',
  YEARLY = 'Tahunan',
  QUARTER = 'Quarter 3 Bulan',
}

export const evaluationScheduleOptions: IOptions[] = [
  {
    label: 'Harian',
    value: 'DAILY',
  },
  {
    label: 'Mingguan',
    value: 'WEEKLY',
  },
  {
    label: 'Bulanan',
    value: 'MONTHLY',
  },
]
