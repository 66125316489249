function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"reward-and-punishment"},[_c('div',{staticClass:"flex flex-row items-center justify-between mb-8"},[_c('h2',{staticClass:"text-xl font-semibold text-black"},[_vm._v("Bonus & Penalti")]),_c('Button',{staticClass:"font-semibold flex flex-row items-center text-sm gap-2",on:{"click":function () {
          _vm.isModalCreateVisible = true
        }}},[_c('PlusIcon'),_c('span',[_vm._v("Program")])],1)],1),_c('div',{staticClass:"flex flex-row gap-3 px-4 py-3 items-center bg-gray-3 rounded-md text-bgMatterhorn text-sm mb-3"},[_c('WarningIcon'),_c('span',[_vm._v("Sebelum membuat Bonus & Penalti, silakan menambahkan program terlebih dahulu, ya!")])],1),_c('DataTableV2',{attrs:{"headers":_vm.headers,"dataItems":_vm.listData,"isLoading":_vm.controller.isLoading},scopedSlots:_vm._u([{key:"5",fn:function(ref){
        var rest = objectWithoutProperties( ref, [] );
        var data = rest;
return [_c('Badge',{attrs:{"customPadding":"","badgeStyle":"w-28 py-1 rounded-2xl text-center","type":_vm.decidePeriodeStatusType(data.data.value)}},[_c('span',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(data.data.value)+" ")])])]}},{key:"6",fn:function(ref){
        var rest = objectWithoutProperties( ref, [] );
        var data = rest;
return [_c('div',{staticClass:"flex flex-row text-bgPrimary font-semibold text-sm gap-10"},[_c('div',{class:{
            'text-gray-1': data.data.isExpired,
            'cursor-pointer': !data.data.isExpired,
            'cursor-not-allowed': data.data.isExpired,
          },on:{"click":function () {
              if (data.data.isExpired) { return }
              _vm.setUpdateForm(data.data.value)
            }}},[_vm._v(" Edit ")]),_c('router-link',{staticClass:"cursor-pointer",attrs:{"to":{
          name: 'RewardAndPunishmentProgramDetail',
          params: {programId: data.data.value}
        }}},[_vm._v(" Lihat Detail ")]),_c('div',{class:{
            'text-gray-1': data.data.isExpired,
            'cursor-pointer': !data.data.isExpired,
            'cursor-not-allowed': data.data.isExpired,
          },on:{"click":function () {
              if (data.data.isExpired) { return }
              _vm.onConfirmDelete(data.data.value)
            }}},[_vm._v(" Hapus ")])],1)]}},{key:"data-empty",fn:function(){return [_c('EmptyState',{attrs:{"customClass":"flex m-auto py-14","text":"Belum Ada Program Baru","description":"Yuk! Buat program baru untuk kurir dengan klik Tambah Program","classText":"text-sm font-semibold text-bgMatterhorn","classDesc":"text-sm"}})]},proxy:true}])}),_c('PaginationNav',{staticClass:"mt-4",attrs:{"page":_vm.parameters.page,"perPage":_vm.parameters.perPage,"lang":"id","enablePerPageOption":true,"perPageOptions":_vm.perPageOptions,"totalItem":_vm.controller.paginationData.total},on:{"input":function () {
        _vm.fetchProgramList()
      },"onChangeOption":_vm.onChangePerPage},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}}),(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e(),(_vm.isModalCreateVisible)?_c('ModalCreateProgram',{attrs:{"visible":true,"clearForm":_vm.clearFormCreate},on:{"close":function () {
        _vm.isModalCreateVisible = false
      }}}):_vm._e(),_c('ModalUpdateProgram',{attrs:{"visible":_vm.isModalUpdateVisible,"isActive":_vm.isPeriodeActive,"formProp":_vm.updateForm,"programId":_vm.programId},on:{"close":function () {
        _vm.isModalUpdateVisible = false
      }}}),_c('ModalSuccessCreateProgram',{attrs:{"visible":_vm.isModalSuccessCreateVisible},on:{"close":function () {
        _vm.isModalSuccessCreateVisible = false
        _vm.fetchProgramList(true)
      }}}),_c('ModalConfirm',{attrs:{"visible":_vm.isModalConfirmDeleteVisible,"title":"Yakin Hapus Program Ini?","description":"Program akan hilang dari daftar Bonus & Penalti","cancelBtnLabel":"Cek Ulang","actionBtnLabel":"Ya, Hapus"},on:{"cancel":function () {
        _vm.isModalConfirmDeleteVisible = false
      },"action":_vm.onDelete}}),_c('ModalSuccess',{attrs:{"visible":_vm.isModalSuccessDeleteVisible,"title":"Program Berhasil Dihapus"},on:{"action":function () {
        _vm.isModalSuccessDeleteVisible = false
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }