




































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import WarningIcon from '@/app/ui/assets/ics_o_warning_triangles.vue'
import CheckCircleIcon from '@/app/ui/assets/icon_check_circle.vue'

@Component({
  components: {
    Modal,
    Button,
    WarningIcon,
    CheckCircleIcon,
  },
})
export default class ModalSuccessCreateProgram extends Vue {
  @Prop({ default: false }) private visible!: boolean
}
