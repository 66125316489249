
































































































































































import Button from '@/app/ui/components/Button/index.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PlusIcon from '@/app/ui/assets/ics_f_plus_circle_white.vue'
import WarningIcon from '@/app/ui/assets/ics_f_warning_circle_red.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import {
  IHeaderCell,
  IOptions,
  ITableCell,
} from '@/data/infrastructures/misc/interfaces/rewardAndPunishment'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import { Utils } from '@/app/infrastructures/misc/Utils'
import Badge from '@/app/ui/components/Badge/index.vue'
import { periodeStatus } from '@/app/infrastructures/misc/Constants/rewardAndPunishment'
import controller from '@/app/ui/controllers/RewardAndPunishmentController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { Program } from '@/domain/entities/RewardAndPunishment'
import ModalCreateProgram from '../components/Modals/ModalCreateProgram/index.vue'
import ModalSuccessCreateProgram from '../components/Modals/ModalSuccessCreateProgram/index.vue'
import ModalUpdateProgram, {
  IForm,
} from '../components/Modals/ModalUpdateProgram/index.vue'
import { evaluationScheduleOptions } from '@/app/infrastructures/misc/Constants/rewardAndPunishment'
import ModalConfirm from '../components/Modals/ModalConfirm/index.vue'
import ModalSuccess from '../components/Modals/ModalSuccess/index.vue'

@Component({
  components: {
    Button,
    DataTableV2,
    EmptyState,
    PaginationNav,
    PlusIcon,
    WarningIcon,
    Badge,
    LoadingOverlay,
    ModalCreateProgram,
    ModalSuccessCreateProgram,
    ModalUpdateProgram,
    ModalConfirm,
    ModalSuccess,
  },
})
export default class RewardAndPunishmentProgram extends Vue {
  controller = controller
  parameters = {
    page: 1,
    perPage: 10,
  }

  headers = [
    this.headerCellMapper('No', '60px'),
    this.headerCellMapper('Nama Program', '180px'),
    this.headerCellMapper('Periode Mulai', '200px'),
    this.headerCellMapper('Periode Berakhir', '200px'),
    this.headerCellMapper('Jadwal Evaluasi', '140px'),
    this.headerCellMapper('Status', '152px'),
    this.headerCellMapper('Atur', '273px'),
  ]

  perPageOptions: IOptions[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  listData: ITableCell[][] = []

  isModalCreateVisible = false
  isModalUpdateVisible = false
  isModalSuccessCreateVisible = false
  isModalConfirmDeleteVisible = false
  isModalSuccessDeleteVisible = false

  isPeriodeActive = false

  clearFormCreate = false

  evaluationScheduleOptions = evaluationScheduleOptions

  updateForm: IForm = {
    evaluationSchedule: null,
    periodeEnd: null,
    periodeStart: null,
    programName: '',
  }

  programId = NaN

  created(): void {
    this.fetchProgramList(true)
  }

  get params(): Record<string, string | number> {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      timezone: encodeURIComponent('+00:00'),
      lang: 'ID',
    }
  }

  public fetchProgramList(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    this.controller.getAllProgram(this.params)
  }

  public onChangePerPage(perPage: number): void {
    this.parameters.perPage = perPage
    this.fetchProgramList(true)
  }

  public setUpdateForm(id: number): void {
    this.isModalUpdateVisible = true

    const program = controller.programData.find(program => program.id === id)
    this.programId = <number>program?.id
    this.isPeriodeActive =
      this.decidePeriodeStatus(
        <string>program?.periodeStart,
        <string>program?.periodeEnd
      ) === periodeStatus.ACTIVE

    this.updateForm = {
      evaluationSchedule:
        this.evaluationScheduleOptions.find(
          option => option.label === program?.evaluationSchedule
        ) || null,
      periodeEnd: new Date(
        Utils.formatDateWithIDLocale(
          <string>program?.periodeEnd,
          'YYYY-MM-DD HH:mm:ss'
        ) +
          Utils.forceTimeZoneAsiaJakarta()
      ),
      periodeStart: new Date(
        Utils.formatDateWithIDLocale(
          <string>program?.periodeStart,
          'YYYY-MM-DD HH:mm:ss'
        ) +
          Utils.forceTimeZoneAsiaJakarta()
      ),
      programName: <string>program?.programName,
    }
  }

  public onConfirmDelete(id: number): void {
    this.isModalConfirmDeleteVisible = true
    this.programId = id
  }

  public onDelete(): void {
    this.controller.deleteProgram(this.programId)
    this.isModalConfirmDeleteVisible = false
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private tableCellMapper(
    value: string | number,
    colWidth: string,
    isExpired = false
  ): ITableCell {
    return {
      value,
      isExpired,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  private decidePeriodeStatus(start: string, end: string): string {
    const dayInMils = 86400000
    const timeNow =
      new Date(
        Utils.formatDateWithIDLocale(new Date().toISOString(), 'YYYY-MM-DD') +
          ' 17:00:00' +
          Utils.forceTimeZoneAsiaJakarta()
      ).getTime() - dayInMils

    if (
      new Date(
        Utils.formatDateWithIDLocale(start, 'YYYY-MM-DD') +
          ' 17:00:00' +
          Utils.forceTimeZoneAsiaJakarta()
      ).getTime() > timeNow &&
      new Date(
        Utils.formatDateWithIDLocale(end, 'YYYY-MM-DD') +
          ' 17:00:00' +
          Utils.forceTimeZoneAsiaJakarta()
      ).getTime() -
        dayInMils >
        timeNow
    ) {
      return periodeStatus.WAITING
    } else if (
      new Date(
        Utils.formatDateWithIDLocale(start, 'YYYY-MM-DD') +
          ' 17:00:00' +
          Utils.forceTimeZoneAsiaJakarta()
      ).getTime() <= timeNow &&
      new Date(
        Utils.formatDateWithIDLocale(end, 'YYYY-MM-DD') +
          ' 17:00:00' +
          Utils.forceTimeZoneAsiaJakarta()
      ).getTime() -
        dayInMils >=
        timeNow
    ) {
      return periodeStatus.ACTIVE
    }
    {
      return periodeStatus.EXPIRED
    }
  }

  public decidePeriodeStatusType(status: string): string {
    switch (status) {
      case periodeStatus.WAITING:
        return 'warning'
      case periodeStatus.ACTIVE:
        return 'success'
      default:
        return 'error-1'
    }
  }

  @Watch('controller.programData')
  onProgramDataChanged(data: Program[]): void {
    if (data) {
      this.listData = data.map((program, i) => {
        return [
          this.tableCellMapper(
            i + 1 + this.parameters.perPage * (this.parameters.page - 1) + '.',
            '60px'
          ),
          this.tableCellMapper(<string>program.programName, '180px'),
          this.tableCellMapper(
            Utils.formatDateWithIDLocale(
              `${<string>(
                program.periodeStart
              )}${Utils.forceTimeZoneAsiaJakarta()}`,
              'DD MMM YYYY'
            ),
            '200px'
          ),
          this.tableCellMapper(
            Utils.formatDateWithIDLocale(
              `${<string>(
                program.periodeEnd
              )}${Utils.forceTimeZoneAsiaJakarta()}`,
              'DD MMM YYYY'
            ),
            '200px'
          ),
          this.tableCellMapper(<string>program.evaluationSchedule, '140px'),
          this.tableCellMapper(
            this.decidePeriodeStatus(
              <string>program.periodeStart,
              <string>program.periodeEnd
            ),
            '152px'
          ),
          this.tableCellMapper(
            <number>program.id,
            '273px',
            this.decidePeriodeStatus(
              <string>program.periodeStart,
              <string>program.periodeEnd
            ) === periodeStatus.EXPIRED
          ),
        ]
      })
    }
  }

  @Watch('controller.isSuccessCreateProgram')
  onIsSuccessCreateProgramChanged(data: boolean): void {
    if (data) {
      this.isModalCreateVisible = false
      this.isModalSuccessCreateVisible = true
      this.clearFormCreate = true
      this.controller.setIsSuccessCreateProgram(false)
    }
  }

  @Watch('controller.isSuccessUpdateProgram')
  onIsSuccessUpdateProgramChanged(data: boolean): void {
    if (data) {
      this.isModalUpdateVisible = false
      this.controller.setIsSuccessUpdateProgram(false)
      this.fetchProgramList(true)
    }
  }

  @Watch('controller.isSuccessDeleteProgram')
  onIsSuccessDeleteProgramChanged(data: boolean): void {
    if (data) {
      this.isModalSuccessDeleteVisible = true
      this.controller.setIsSuccessDeleteProgram(false)
      this.fetchProgramList(true)
    }
  }
}
